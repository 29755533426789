import * as React from "react"
import styled from 'styled-components/macro'
import Checkmark from '@mui/icons-material/Check'

// styles
const pageStyles = {
  color: "#232129",
  fontFamily: "'Open Sans', sans-serif",
  backgroundColor: '#18182a',
  position: 'relative',
}

const PageWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #292938;
  text-align: center;
`

const ContentWrapper = styled.div`
  width: fit-content;
  display: inline-block;
  margin: 20vh auto;
  text-align: center;
  max-width: 500px;
`

const TitleImage = styled.div`
  width: 250px;
  height: auto;
  max-width: 50vw;
  margin: 0 auto;
  display: inline-block;
  
  svg {
    height: 100%;
    width: 100%;
    color: #8fc942;
  }
`

const Title = styled.div`
  font-size: 40px;
  font-weight: 700;
  color: #f9f9f9;
  margin-bottom: 15px;
`

const Subtitle = styled.div`
  font-size: 20px;
  font-weight: 300;
  color: #f9f9f9;
  line-height: 32px;
  max-width: 85vw;
  margin: 0 auto;
`

const Code = styled.code`
  background: #565A69;
  color: #f9f9f9;
  padding: 5px;
  border-radius: 5px;
`

const VerificationCompletePage = () => {
  return (
    <main style={pageStyles}>
      <PageWrapper>
        <ContentWrapper>
          <TitleImage><Checkmark /></TitleImage>
          <Title>Verification Complete!</Title>
          <Subtitle>You can close this window and return to Discord, the <Code>!level</Code> command should work for you now.</Subtitle>
        </ContentWrapper>
      </PageWrapper>
    </main>
  )
}

export default VerificationCompletePage
